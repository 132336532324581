import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SecondSection from "./LandingPage/SecondSection";
import Characters from "./LandingPage/Characters";
import OurcommunitySection from "./LandingPage/OurcommunitySection";
import Accordion from "./LandingPage/Accordion";
import Footer from "../components/Footer";
import LandingPopup from "./LandingPopup";

const LandingPage = () => {
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const hasVisited = localStorage.getItem("hasVisited");
        if (!hasVisited) {
            setShowPopup(true);            
            localStorage.setItem("hasVisited", "true");
            const timer = setTimeout(() => {
                localStorage.removeItem("hasVisited");
            }, 3600000);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleClosePopup = () => {
        window.location.reload();
        setShowPopup(false);
    };

    const handleJoinNowClick = () => {
        // Redirect to the /login page
        navigate("/login");
    };

    return (
        <>
            <div className="font-josefin-sans overflow-auto">
                <div className="landingHero w-full py-[160px] md:py-[360px]">
                    <p className="text-white font-bold text-[42px] md:text-[54px] text-center ">
                        Revolutionizing Fantasy
                    </p>
                    <p className="text-white text-[25px] md:text-[40px] text-center pt-3 tracking-widest">
                        The Ultimate AI-Driven Visual Experience
                    </p>
                    <div className="flex justify-center pt-20">
                        <button
                            onClick={handleJoinNowClick}
                            className="mx-10 w-full md:w-[470px] px-9 py-3 text-center md:text-left text-xl text-white border-2 border-white rounded-md bg-pink-950 opacity-60 hover:cursor-pointer hover:bg-pink-800"
                        >
                            JOIN NOW
                        </button>
                    </div>
                </div>
                {/*--- second section ---*/}
                <SecondSection />
                {/*--- our community members section ---*/}
                <OurcommunitySection />
                {/*--- Characters section ---*/}
                <Characters />
                <Accordion />
                <Footer />
            </div>
            {showPopup && <LandingPopup onClose={handleClosePopup} />}
        </>
    );
};

export default LandingPage;
