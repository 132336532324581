import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
    const authToken = localStorage.getItem('authToken');
     if (!authToken) {
        console.log('authToken',authToken)

            // Redirect to login or show an error
            alert('No authentication found. Please log in.');
            }else{
                console.log('hereeeeeeeeeeeeeeeeee')
            }
    return authToken ? children : <Navigate to="/admin" replace />;
};

export default PrivateRoute;
