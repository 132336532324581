import React from "react";
import {
    Rectangle73,
} from "../../../assets";
import { buttonsData, imageData } from "../../../assets/data";
import HoverButton from "../../../dashboard/components/HoverButton";


const Characters = () => {
    return (
        <div>
            <div className="bg-[#141313] text-white pb-24">
                <div className="text-center py-12">
                    <p className="text-[18px] lg:text-[24px] xl:text-[36px]">AI Girls and Characters Revisited</p>
                    <p className="text-[16px] lg:text-[18px] xl:text-[22px] leading-none font-light xl:font-normal py-3 2xl:mx-[599px] xl:mx-[300px] md:mx-[150px] mx-5 ">
                        With EROMANTIC<span className="text-red-600">AI</span>, you can save
                        and reuse previously generated girls or characters, enabling them to
                        appear in endless scenarios across generations.
                    </p>
                </div>
                <div className="border-2 border-[#FF0013] grid grid-cols-10 xl:mx-72 rounded-2xl">

                    <div className="col-span-2 hidden md:block">
                        {imageData.map((image, index) => (
                            <div key={index} className="relative w-full p-0 m-0 group">
                                <div className="image-wrapper cursor-pointer">
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        className="block w-full h-auto p-0 m-0 image"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="col-span-10 md:col-span-8">
                        <div>
                            <div className="flex justify-center md:justify-start flex-wrap gap-5 m-10">
                                {buttonsData.map((button, index) => (
                                    <div key={index}>
                                        <HoverButton placeholder={button.placeholder} width={button.width} />
                                    </div>
                                ))}
                            </div>
                            <div className="mx-12">
                                <img src={Rectangle73} alt="Rectangle73" className="w-full h-full hidden md:block" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Characters;
