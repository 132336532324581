import React, { useEffect, useRef, useState } from 'react';
import { CgMenuRightAlt } from 'react-icons/cg';
import { IoClose, IoSettingsSharp } from 'react-icons/io5';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { bell, eheader, Logo, userHeader, userProfileHeader } from '../../assets';
import { FaAngleDown, FaUserGear, FaUserLarge } from 'react-icons/fa6';

const FrontendHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Userlogger, setUserlogger] = useState(null);

    const location = useLocation();

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsPopupVisible(false);
        }
    };

    const handleRequest = () => {
        setIsModalOpen(true);
        setIsPopupVisible(false);
        setIsMenuOpen(!isMenuOpen);
        setIsDropdownOpen(!isDropdownOpen);
    }

    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("login");
        localStorage.removeItem("hasVisited");
        navigate('/login')
    };
    const handleClickMenu = () => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        const login = localStorage.getItem('login');

        // Check if all required items are present in localStorage
        if (token && refreshToken && login) {
            navigate('/dashboard/girls');
        } else {
            navigate('/');  // Otherwise, navigate to the home page
        }
    };


    useEffect(() => {
        setUserlogger(localStorage.getItem('login'));
        if (isPopupVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupVisible]);

    const navData = [
        { name: 'DISCOVER', link: '/dashboard/discover' },
        { name: 'GIRLS', link: '/dashboard/girls' },
        { name: 'GENERATIONS', link: '/dashboard/generations' },
        { name: 'PREMIUM', link: '/dashboard/creators' },
        { name: 'GUIDE', link: '/joinhgh' },
    ];


    const userPagesdata = [
        { icon: <FaUserLarge size={20} />, name: 'My Profile', path: '/dashboard/accountProfile' },
        { icon: <IoSettingsSharp size={20} />, name: 'Gallery', path: '/dashboard/gallery' },
        { icon: <IoSettingsSharp size={20} />, name: 'Model Verification', path: '/dashboard/modelVerification' },
        { icon: <IoSettingsSharp size={20} />, name: 'Modality', path: '/dashboard/modality' },
        { icon: <IoSettingsSharp size={20} />, name: 'Referrals', path: '/dashboard/referrals' },
        { icon: <IoSettingsSharp size={20} />, name: 'Support', path: '/dashboard/support' },
    ]

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    console.log("sssssssssss", Userlogger)

    return (
        <>
            <div className='fixed top-0 left-0 right-0 flex justify-between gap-5 px-2 xl:px-28 py-4 items-center bg-white z-1000' style={{ zIndex: 1000 }}>
                <div onClick={handleClickMenu} className='cursor-pointer'> <img src={Logo} alt="logo" className='w-56 md:w-56' /></div>

                {
                    Userlogger ?
                        <div className='flex items-center gap-8'>
                            <div className='hidden lg:flex'>
                                <nav>
                                    <ul className='flex gap-5 xl:gap-10 font-semibold'>
                                        {navData.map((item, index) => (
                                            <li key={index} className={`hover:cursor-pointer text-lg hover:text-gray-500 ${location.pathname === item.link ? 'text-red-500' : ''}`}>
                                                <Link to={item.link}>{item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                            <div className='lg:flex justify-between items-center gap-5 xl:gap-8 hidden'>
                                <button className='bg-red-800 hover:bg-red-700 py-2 px-4 rounded-full flex gap-2 items-center justify-center'>
                                    <img src={eheader} alt="eheader" className='w-6' />
                                    <p className='text-white font-bold text-2xl'>0</p>
                                </button>
                                <div>
                                    <img src={bell} alt="bell" />
                                </div>
                                <div className=''>
                                    <img
                                        onClick={() => setIsPopupVisible(true)}
                                        src={userHeader}
                                        alt="userHeader"
                                        className='hover:cursor-pointer w-[60px] h-[60px] rounded-full'
                                    />
                                </div>

                            </div>
                        </div>
                        :
                        <div className='flex items-center gap-8'>
                            <div className='hidden lg:flex'>
                                <nav>
                                    <ul className="flex gap-[40px]">
                                        <li className="text-black hover:text-[#FF0013] text-[21px]">
                                            <NavLink to="#">Pricing plan</NavLink>
                                        </li>
                                        <li className="text-black hover:text-[#FF0013] text-[21px]">
                                            <NavLink to="https://eromantic.ai/blogs">Blogs</NavLink>
                                        </li>
                                        <li className="text-black hover:text-[#FF0013] text-[21px]">
                                            <NavLink to="/login">Log In</NavLink>
                                        </li>
                                        <li className="text-black hover:text-[#FF0013] text-[21px]">
                                            <NavLink to="/join">Join Now</NavLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                }




                <div className='lg:hidden hover:cursor-pointer'>
                    {isMenuOpen ? <IoClose size={28} onClick={handleMenuToggle} /> : <CgMenuRightAlt size={28} onClick={handleMenuToggle} />}
                </div>
            </div>
            {isMenuOpen && (
                <div className='bg-white lg:hidden pb-5 z-50  w-full flex justify-start gap-5'>
                    <nav>
                        <ul className='flex flex-col ms-5 gap-4 font-semibold'>
                            {navData.map((item, index) => (
                                <li key={index} className={`hover:cursor-pointer hover:text-gray-500 ${location.pathname === item.link ? 'text-red-500' : ''}`}>
                                    <Link to={item.link} onClick={handleMenuToggle}>{item.name}</Link>
                                </li>
                            ))}
                        </ul>
                        <div className='ps-5 gap-3 flex flex-col py-3 '>
                            <button className='bg-red-800 w-fit hover:bg-red-700  py-2 px-4 rounded-full flex gap-2 items-center'>
                                <img src={eheader} alt="eheader" />
                                <p className='text-white font-bold text-2xl'>0</p>
                            </button>
                            <div>
                                <img src={bell} alt="bell" />
                            </div>
                            <div className="pb-4 mt-2">
                                <button
                                    onClick={handleLogout}
                                    className="w-32 py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg">
                                    Logout
                                </button>
                            </div>
                        </div>
                    </nav>
                    <div className="relative z-50 w-[240px] pr-2 md:px-5">
                        <div onClick={handleDropdownToggle} className='flex justify-between items-center hover:cursor-pointer'>
                            <button className="flex justify-center items-center gap-2">
                                <FaUserGear size={25} />
                                <p className="font-semibold">User</p>
                            </button>
                            <FaAngleDown />

                        </div>
                        {isDropdownOpen && (
                            <div className="absolute mt-2 w-full bg-white">
                                <div className="font-semibold">
                                    {
                                        userPagesdata.map((item, index) => (
                                            <NavLink to={item.path} key={index} onClick={handleDropdownToggle}>
                                                <div className='flex justify-start gap-5 pt-2 items-center'>
                                                    {item.icon}
                                                    <p className={`hover:cursor-pointer hover:text-gray-500 ${location.pathname === item.path ? 'text-red-500' : ''}`} onClick={handleMenuToggle}>{item.name}</p>
                                                </div>
                                            </NavLink>
                                        ))
                                    }
                                    <div onClick={handleRequest} className='flex pt-2 justify-start gap-5 items-center hover:cursor-pointer'>
                                        <IoSettingsSharp size={20} />
                                        <p className="hover:cursor-pointer hover:text-gray-500">Request a feature</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}


            {isPopupVisible && (
                <div className="fixed right-0 2xl:top-[7%] z-50 flex justify-end pr-16 text-white font-josefin-sans">
                    <div ref={popupRef} className="bg-black border-2 border-white rounded-lg w-72 mt-2 text-center">
                        <div className='flex gap-5 py-5 justify-center items-center border-b-2 border-stone-300'>
                            <div>
                                <img src={userProfileHeader} alt="userProfileHeader" className='w-12 h-12 rounded-full' />
                            </div>
                            <div className='text-start'>
                                <p className='text-[17px] font-medium'>{Userlogger}</p>
                            </div>
                        </div>
                        <div className='ps-7'>
                            {
                                userPagesdata.map((item, index) => (
                                    <NavLink key={index} to={item.path} onClick={() => setIsPopupVisible(false)}>
                                        <div className='flex pt-2 justify-start gap-5 items-center'>
                                            {item.icon}
                                            <p className="text-xl font-bold">{item.name}</p>
                                        </div>
                                    </NavLink>
                                ))
                            }
                            <div onClick={handleRequest} className='flex pt-2 justify-start gap-5 items-center hover:cursor-pointer'>
                                <IoSettingsSharp size={20} />
                                <p className="text-xl font-bold">Request a feature</p>
                            </div>
                        </div>
                        <div className='px-7'>
                            <button
                                onClick={handleLogout}
                                className="px-4 my-5 w-full py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg">
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="h-[810px] md:h-[965px] lg:h-[100vh] fixed inset-0 flex items-center justify-center bg-black font-josefin-sans bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg w-[350px] md:w-[650px] lg:w-[900px] p-7 xl:px-16 xl:py-12 relative z-50">
                        <button
                            className="absolute top-3 right-3 text-lg font-bold z-50"
                            onClick={() => setIsModalOpen(false)}
                        >
                            <IoClose size={23} />
                        </button>
                        <h2 className="text-center text-3xl font-semibold mb-2">Request a feature</h2>
                        <p className="text-center text-stone-500 text-xl font-semibold mb-4">
                            Have a certain feature in mind or want us to improve certain processes? Feel
                            free to let us know down below.
                        </p>
                        <textarea
                            className="w-full text-lg lg:text-xl h-32 p-2 border text-black focus:outline-none border-black rounded-md"
                            placeholder="Type here..."
                        />
                        <div className="flex justify-center mt-4">
                            <button className="bg-red-800 hover:bg-red-700 w-72 text-2xl text-white font-semibold rounded-xl px-6 py-2">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default FrontendHeader;
