import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from '../../components/Togal';
import { IoClose } from 'react-icons/io5';
import { DeleteImg, eye, rename } from '../../../assets';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import axios from 'axios';

const Faqs = () => {
    const navigate = useNavigate();

    const handleBlog = () => {
        navigate('/admin/addFaqs');
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [faqsList, setfaqsList] = useState([]);
    const [popupQuestion, setpopupQuestion] = useState('');
    const [popupAnswer, setpopupAnswer] = useState('');

    const togglePopup = (question = '', answer = '') => {
        setIsPopupOpen(!isPopupOpen);
        setpopupQuestion(question);
        setpopupAnswer(answer);
    };

    const handleClose = () => {
        setIsPopupOpen(false);
        setpopupQuestion('');
        setpopupAnswer('');
    };

      const handleDelete = async (faqsId) => {
        try {
            const authToken = localStorage.getItem('authToken');
            await axios.delete(`https://api2.eromantic.ai/eromantic_api/faqs/delete/${faqsId}/`, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });
             window.location.reload();
        } catch (error) {
            console.error('Error deleting staff:', error);
        }
    };


     const toggleStatus = async (faqsId, currentStatus) => {

        const faqsToUpdate = faqsList.find(faqs => faqs.id === faqsId);

        if (!faqsToUpdate) {
            console.error('Staff member not found');
            return;
        }

        const updatedfaqs = {
            ...faqsToUpdate,
            status: !currentStatus // Toggle the status
        };

        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.put(`https://api2.eromantic.ai/eromantic_api/faqs/edit/${faqsId}/`, updatedfaqs, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });

            if (response.status === 200) {
                setfaqsList(prevfaqsList =>
                    prevfaqsList.map(faqs =>
                        faqs.id === faqsId ? updatedfaqs : faqs
                    )
                );
            }
        } catch (error) {
            console.error('Error updating status:', error.response?.data || error.message);
            alert('Failed to update status');
        }
    };

    useEffect(() => {
        // Fetch the FAQ list when the component is mounted
        const fetchfaqsList = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get('https://api2.eromantic.ai/eromantic_api/faqs/', {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                });
                setfaqsList(response.data);
            } catch (error) {
                console.error('Error fetching FAQs list:', error);
            }
        };

        fetchfaqsList();
    }, []);

    return (
        <>
            <div className="bg-white px-7 pb-20 font-josefin-sans">
                <PagenameSearchBox
                    title="FAQs List"
                    placename="Search Blog"
                    btnname="Add FAQ"
                    btnfunction={handleBlog}
                />
                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-fixed">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-2 2xl:px-6 py-3 w-[33%]">
                                    Questions
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 w-[33%]">
                                    Answers
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">
                                    Pages
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {faqsList.map((faqs) => (
                                <tr
                                    key={faqs.id}
                                    className="bg-white text-xl font-normal text-black border-b border-stone-300"
                                >
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                        {faqs.question}
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                        {faqs.answer}
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 whitespace-nowrap">
                                        {faqs.page}
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4">
                                        <Togal isChecked={faqs.status} value={faqs.status} handleChange={() => toggleStatus(faqs.id, faqs.status)} />
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                                        <img
                                            src={eye}
                                            alt="eye"
                                            className="hover:cursor-pointer"
                                            onClick={() => togglePopup(faqs.question, faqs.answer)}
                                        />

                                        <img
                                            src={DeleteImg}
                                            alt="delete"
                                            className="hover:cursor-pointer"
                                            onClick={() => handleDelete(faqs.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-10">
                    <div className="bg-white pt-5 pb-10 px-10 rounded-lg text-left shadow-lg w-[1100px]">
                        <div className="relative left-[100%]">
                            <IoClose
                                size={29}
                                onClick={handleClose}
                                className="hover:cursor-pointer"
                            />
                        </div>
                        <p className="mb-4 font-semibold text-2xl">Question</p>
                        <p className="mb-4 font-semibold text-lg">{popupQuestion}</p>
                        <p className="mb-4 font-semibold text-2xl">Answer</p>
                        <p className="mb-4 font-semibold text-lg">{popupAnswer}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Faqs;
