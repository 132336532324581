import React from 'react'
import { Rectangle10, Rectangle11 } from '../../../assets'

const SecondSection = () => {
    return (
        <div> <div className="id:CUSTOM POSE bg-[#0E0E0E]">
            <div>
                <p className="hero text-center md:text-left px-2 xs:px-5 md:px-[42px] lg:px-[56px] text-[20px] xl:text-[36px] py-8 lg:py-10 xl:py-16 leading-none">
                    Effortlessly Craft Your Fantasy:
                    <br />
                    High-Quality AI-Generated Content at Your Fingertips
                </p>
                {/*first image section */}
                <div className="py-5 md:py-9 lg:py-12 grid grid-cols-1 md:grid-cols-10 gap-10 md:gap-20">
                    <div className="col-span-5">
                        <img
                            src={Rectangle10}
                            alt="Rectangle"
                            className="opacity-[70%]"
                        />
                    </div>
                    <div className="col-span-5 flex flex-col md:justify-center text-right md:text-left">
                        <div className="text-white mx-5 md:mx-0">
                            <p className="text-[18px] lg:text-[24px] xl:text-[36px]">CUSTOM POSE</p>
                            <p className="text-[16px] lg:text-[18px] xl:text-[30px] md:w-[230px] lg:w-[300px] xl:w-[500px] font-light xl:font-normal leading-none py-2 xl:py-5 pl-28 md:pl-0">
                                Eromantic AI "Custom Pose" AI: Upload, generate, and enjoy
                                seamless character pose transfers.
                            </p>
                            <button
                                type="button"
                                className="text-white bg-[#FF0013] hover:bg-[#FF0013]  rounded-[55px] text-[18px] lg:text-[24px] xl:text-[35px] px-3  xl:px-5 text-center w-[100px] lg:w-[130px] xl:w-[210px] h-[36px] lg:h-[45px] xl:h-[65px] font-light xl:font-normal mt-4 lg:mt-6 xl:mt-12"
                            >
                               Live Soon..
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            {/* second image section */}
            <div className="bg-[#141313]">
                <div className="py-12 grid grid-cols-1 md:grid-cols-10 gap-10 md:gap-0">
                    <div className="col-span-5 flex flex-col justify-center ">
                        <div className="text-white pl-[26px] md:pl-[42px] lg:pl-[72px]">
                            <p className="text-[18px] lg:text-[24px] xl:text-[36px]">IN PAINT</p>
                            <p className="text-[16px] lg:text-[18px] xl:text-[30px] w-[290px] md:w-[310px] lg:w-[350px] xl:w-[600px] font-light xl:font-normal leading-none py-2 xl:py-5">
                                Refine your image with Eromantic AI's "In Paint" feature. Click "Edit," select, and watch the magic happen!
                            </p>
                            <button
                                type="button"
                                className="text-white bg-[#FF0013] hover:bg-[#FF0013]  rounded-[55px] text-[18px] lg:text-[24px] xl:text-[35px] px-3  xl:px-5 text-center w-[100px] lg:w-[130px] xl:w-[210px] h-[36px] lg:h-[45px] xl:h-[65px] font-light xl:font-normal mt-4 lg:mt-6 xl:mt-12"
                            >
                                Live Soon..
                            </button>
                        </div>
                    </div>
                    <div className="col-span-5">
                        <img
                            src={Rectangle11}
                            alt="Rectangle"
                            className=" opacity-[70%] "
                        />
                    </div>
                </div>
            </div></div>
    )
}

export default SecondSection