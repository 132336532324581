import React from 'react';

const HoverButton = ({ placeholder, width }) => {
    return (
        <div className="hover-button-wrapper">
            <button className={`text-[20px] w-72 md:w-fit rounded-full px-10 bg-[#FF0013] py-2 w-[${width}] hover-button`}>
                {placeholder}
            </button>
        </div>
    );
};

export default HoverButton;
