import React, { useState } from 'react';
import { accordionData } from '../../../assets/data';

const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleCollapse = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className='bg-red-800 pb-32'>
            <div>
                <p className='text-center text-[36px] text-white py-20'>Frequently asked questions</p>
                <div className='border border-slate-100 mx-5'>
                    {accordionData.map((item, index) => (
                        <div className="relative " key={index}>
                            <h6 className="mb-0">
                                <button
                                    className={`relative flex items-center w-full p-4 font-semibold text-left transition-all border-b-1 border-t ease-in  cursor-pointer  text-white  group text-dark-500 ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => toggleCollapse(index)}
                                    style={{
                                        backgroundColor: activeIndex === index ? 'black' : '',
                                        color: activeIndex === index ? 'white' : 'black',
                                    }}
                                >
                                    <span className='text-xl' style={{ color: 'white' }}>{item.title}</span>
                                    <i className={`absolute right-0 pt-1 pr-5 text-xl font-bold fa ${activeIndex === index ? 'fa-minus text-black' : 'fa-plus text-white'}`}></i>
                                </button>
                            </h6>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${activeIndex === index ? 'h-auto' : 'h-0'}`}
                            >
                                <div className="px-8 pb-10 pt-4 text-sm leading-normal text-white text-[20px]">
                                    {item.content}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
