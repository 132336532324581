import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const AIBoyfriend = () => {

    const navigate = useNavigate();
    const NewGenerate = () => {
        // navigate("/dashboard");
    }


    return (
        <>
          Boyfriend
        </>
    );
};

export default AIBoyfriend;
