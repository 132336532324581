import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AIGirlfriend from './AIGirlfriend.js';
import AIBoyfriend from './AIBoyfriend.js';

const CreateDreamGF = () => {
    const [activeComponent, setActiveComponent] = useState('AIGirlfriend');

    const navigate = useNavigate();
    const NewGenerate = () => {
        // navigate("/dashboard");
    }


    return (
        <>
            <div className='px-5 xl:px-20 pb-20 bg-black pt-10 text-white font-josefin-sans ' style={{ minHeight: '852px' }}>
                <div>
                    <p className='text-[35px] py-2'>Generate New</p>
                    <div className='md:rounded-tr-xl md:rounded-br-xl md:rounded-tl-xl md:rounded-bl-xl md:flex justify-between lg:text-xl bg-red-600'>
                        <button
                            className={`w-full flex md:rounded-tl-xl md:rounded-bl-xl items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'AIGirlfriend' ? 'bg-red-600 hover:border-red-800' : 'bg-red-800'} md:justify-center ps-12 md:ps-0`}
                            onClick={() => setActiveComponent('AIGirlfriend')}
                        >
                            {/* <img src={starImg} alt="starImg" /> */}
                            <p className=''>AI Girlfriend</p>
                        </button>
                        <button
                            className={`w-full flex items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'AIBoyfriend' ? 'bg-red-600 hover:border-red-800' :  'bg-red-800'} md:justify-center ps-12 md:ps-0`}
                            onClick={() => setActiveComponent('AIBoyfriend')}
                        >
                            {/* <img src={advanceImg} alt="advanceImg" className='h-8' /> */}
                            <p className=''>AI Boyfriend</p>
                        </button>
                    </div>
                </div>

                {activeComponent === 'AIGirlfriend' ? <AIGirlfriend /> :
                    activeComponent === 'AIBoyfriend' ? <AIBoyfriend /> : ''}

            </div>
        </>
    );
};

export default CreateDreamGF;
