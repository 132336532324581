import React, { useState } from 'react'
import EasyTrackingBox from '../../components/EasyTrackingBox'
import { videoPlay } from '../../../assets'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const Referrals = () => {

    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = () => {
        const textToCopy = "https://www.eromantic.ai?ref=6638b4f04b073b3fadebe7d9";
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text', err);
        }

        document.body.removeChild(textArea);
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (
        <>
            <div className='mt-20 px-2 md:px-5 xl:px-20  pb-20 bg-black pt-10 text-white font-kanit'>
                <p className='text-center text-[40px]'>Referrals</p>
                <div className='flex justify-center pt-3'>
                    <p className='text-2xl xl:w-[70%] text-center'>Earn commissions by referring users to Seduced and receiving a share of up to 40% from their plan purchases or renewals.</p>
                </div>
                <div className='w-full flex flex-wrap gap-5 justify-center mt-16'>
                    <div className=' flex flex-col justify-center gap-8'>
                        <EasyTrackingBox name='Lucrative Commissions:' text='Pocket a generous 25% of net revenue for every member you bring on board! With premium plans ranging from $20-$120, your earning potential is seriously high.' />

                        <EasyTrackingBox name='Passive Income Stream:' text='Enjoy recurring revenue month after month, as long as your referrals remain subscribed. Build a base of referrals and watch your income grow.' />

                        <EasyTrackingBox name='Easy Tracking:' text='Our advanced affiliate dashboard gives you real-time insights. See exactly who signs up through your link and track your earnings with crystal clarity.' />
                    </div>
                    <div className='flex flex-col justify-center gap-8'>
                        <div >
                            <img src={videoPlay} alt="videoPlay" className='rounded-xl md:w-[630px] 2xl:w-[730px] h-[220px] md:h-[390px]' />
                        </div>
                        <EasyTrackingBox name='Premium Product, High Conversions:' text='You will be promoting a cutting-edge AI image generation solution that is in high demand. Our proven marketing materials help you close sales effortlessly.' />
                    </div>
                </div>

                <div className='border-2 border-zinc-600 rounded-xl p-5 mt-10'>
                    <p className='text-2xl text-left pb-2'>Your tracking link</p>
                    <div className="flex items-center border-2 border-zinc-600 rounded-xl p-2 bg-black text-white">
                        <input
                            type="text"
                            value="https://www.eromantic.ai?ref=6638b4f04b073b3fadebe7d9"
                            readOnly
                            className="flex-grow bg-black text-white border-none focus:outline-none focus:ring-0"
                        />
                        <button
                            onClick={copyToClipboard}
                            className="ml-2 px-1 md:px-4 py-2 bg-white text-red-600 rounded-md hover:bg-gray-300"
                        >
                            {copySuccess ? 'Copied' : 'CopyLink'}
                        </button>
                    </div>
                </div>

                <div className="p-4 border-2 border-zinc-600 text-white rounded-lg mt-10">
                    <div className="mb-4 flex items-center justify-start gap-10">
                        <span className="text-2xl ">Sales history</span>
                        <div className="flex flex-wrap items-center justify-center border-2 border-zinc-600">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="md:px-2 py-1 text-center w-fit bg-[#61616100] rounded text-white"
                            />
                            <span>-</span>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                className="md:px-2 py-1 text-center w-fit bg-[#61616100]  rounded text-white"
                            />
                        </div>
                    </div>

                    <div className="relative overflow-x-auto sm:rounded-lg mt-10 font-josefin-sans">
                        <table className="w-full text-left ">
                            <thead className=" border-t-2 border-zinc-600 text-white">
                                <tr className='text-lg'>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Date</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Renewal</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Src</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">ID</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Payment method</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Gross</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Gateway fees</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Refunded</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Charged back</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Affiliate %</th>
                                    <th className="px-4 py-2 text-left uppercase tracking-wider">Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Body here */}
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

        </>
    )
}

export default Referrals