import React, {useState} from 'react'
import { goldE, info } from '../../assets'
import {useNavigate } from 'react-router-dom';

const FooterMakeAI = () => {
const [selected, setSelected] = useState('medium');
const [aiselected, setaiSelected] = useState('creative');
const [MagicSelected, setMagicSelected] = useState('none');
const [svgselected, setsvgSelected] = useState('fourth');

  const MagicClick = (value) => {
    setMagicSelected(value);
  };
  const handleClick = (value) => {
    setSelected(value);
  };
  const handleaiClick = (value) => {
    setaiSelected(value);
  };

   const handlesvgClick = (value) => {
    setsvgSelected(value);
  };

  const navigate = useNavigate()
  const generate = () => {
   navigate('/dashboard/ImageView')
  }
   return (
        <>
            <div>
                <div className='flex flex-wrap justify-between pt-20'>
                    <div className='bg-black flex flex-col gap-5 lg:gap-12 text-white font-josefin-sans'>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Steps</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                <button
                                    onClick={() => handleClick('medium')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'medium' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    Medium
                                  </button>
                                  <button
                                    onClick={() => handleClick('high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'high' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    High
                                  </button>
                                  <button
                                    onClick={() => handleClick('very-high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'very-high' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    Very High
                                  </button>
                                  <button
                                    onClick={() => handleClick('extremely-high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'extremely-high' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    Extremely High
                                  </button>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Magic</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                 <p
                                    onClick={() => MagicClick('none')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer transition-colors duration-300 ${MagicSelected === 'none' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    None
                                  </p>
                                  <p
                                    onClick={() => MagicClick('normal')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer transition-colors duration-300 ${MagicSelected === 'normal' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    Normal
                                  </p>
                                  <p
                                    onClick={() => MagicClick('high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer transition-colors duration-300 ${MagicSelected === 'high' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    High
                                  </p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Variations</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <input type="number" className='focus:outline-none bg-zinc-300 rounded-xl px-5 py-1 mt-3 w-52 text-black text-2xl' />
                        </div>
                        <div className='flex items-center gap-5 md:mb-3 lg:mb-0'>
                            <input
                                type="radio"
                                className='w-7 h-7'
                            />
                            <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make Private</p>
                        </div>
                    </div>
                    <div className='bg-black flex flex-col gap-5 lg:gap-12 text-white font-josefin-sans'>
                        <div className='mt-3'>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make AI listen</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                 <p
                                    onClick={() => handleaiClick('creative', 'Let it be creative')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'creative' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    Let it be creative
                                  </p>
                                  <p
                                    onClick={() => handleaiClick('normal', 'Normal A lot')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-light cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'normal' ? 'text-red-500' : 'text-white'}`}
                                  >
                                    Normal A lot
                                  </p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Aspect ratio <span className='text-base md:text-lg lg:text-xl xl:text-2xl'>(square, horizontal, vertical)</span></p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 text-center mt-2'>
                            <svg
                                onClick={() => handlesvgClick('first')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'first' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="39" height="39" rx="6.5" strokeWidth="2"></rect>
                              </svg>
                              <svg
                                onClick={() => handlesvgClick('second')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'second' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="62"
                                height="42"
                                viewBox="0 0 62 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="59" height="39" rx="6.5" strokeWidth="2"></rect>
                              </svg>
                              <svg
                                onClick={() => handlesvgClick('third')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'third' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="29"
                                height="42"
                                viewBox="0 0 29 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="26" height="39" rx="6.5" strokeWidth="2"></rect>
                              </svg>
                              <svg
                                onClick={() => handlesvgClick('fourth')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'fourth' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="29"
                                height="42"
                                viewBox="0 0 29 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="26" height="39" rx="6.5" strokeWidth="2"></rect>
                                <path d="M5.399 22.194V21.459L9.158 16.65H10.292L6.5645 21.459L6.029 21.291H11.8985V22.194H5.399ZM9.4835 24V22.194L9.515 21.291V19.695H10.502V24H9.4835ZM14.7627 19.821C14.5667 19.821 14.3987 19.7545 14.2587 19.6215C14.1187 19.4815 14.0487 19.3065 14.0487 19.0965C14.0487 18.8865 14.1187 18.715 14.2587 18.582C14.3987 18.449 14.5667 18.3825 14.7627 18.3825C14.9517 18.3825 15.1127 18.449 15.2457 18.582C15.3857 18.715 15.4557 18.8865 15.4557 19.0965C15.4557 19.3065 15.3857 19.4815 15.2457 19.6215C15.1127 19.7545 14.9517 19.821 14.7627 19.821ZM14.7627 24.063C14.5667 24.063 14.3987 23.9965 14.2587 23.8635C14.1187 23.7235 14.0487 23.5485 14.0487 23.3385C14.0487 23.1285 14.1187 22.957 14.2587 22.824C14.3987 22.691 14.5667 22.6245 14.7627 22.6245C14.9517 22.6245 15.1127 22.691 15.2457 22.824C15.3857 22.957 15.4557 23.1285 15.4557 23.3385C15.4557 23.5485 15.3857 23.7235 15.2457 23.8635C15.1127 23.9965 14.9517 24.063 14.7627 24.063ZM20.2916 24.084C19.7736 24.084 19.2696 24.0035 18.7796 23.8425C18.2966 23.6815 17.9011 23.4645 17.5931 23.1915L18.0761 22.362C18.3211 22.593 18.6396 22.782 19.0316 22.929C19.4236 23.076 19.8401 23.1495 20.2811 23.1495C20.8411 23.1495 21.2716 23.0305 21.5726 22.7925C21.8736 22.5545 22.0241 22.236 22.0241 21.837C22.0241 21.564 21.9576 21.326 21.8246 21.123C21.6916 20.92 21.4606 20.766 21.1316 20.661C20.8096 20.549 20.3651 20.493 19.7981 20.493H18.1286L18.5171 16.65H22.6646V17.5635H18.9056L19.4516 17.049L19.1471 20.0835L18.6011 19.5795H20.0186C20.7536 19.5795 21.3451 19.674 21.7931 19.863C22.2411 20.052 22.5666 20.3145 22.7696 20.6505C22.9726 20.9795 23.0741 21.361 23.0741 21.795C23.0741 22.215 22.9726 22.6 22.7696 22.95C22.5666 23.293 22.2586 23.5695 21.8456 23.7795C21.4396 23.9825 20.9216 24.084 20.2916 24.084Z" fill="white"></path>
                              </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={generate} className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
                    Generate for 1.00
                    <img src={goldE} alt="goldE" />
                </button>
            </div>
        </>
    )
}

export default FooterMakeAI