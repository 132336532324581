import React, { useState, useEffect } from 'react';
// import { FixedSizeGrid as Grid } from 'react-window';
import axios from 'axios';
import ImgDetailOnhoverGenration from '../../components/ImgDetailOnhoverGenration'
import { AiFillLike } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa6';
import { GiRoundStar } from 'react-icons/gi';
import { IoIosTime } from 'react-icons/io';
import { IoSearchOutline } from 'react-icons/io5';
import { filter } from '../../../assets';
import { filterOn } from '../../../assets';
import { useNavigate } from 'react-router-dom'


const Discover = () => {
    const [imageData, setImageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [userDetails, setUserDetails] = useState('');
    const navigate = useNavigate();

    const Showcasedata = [
        { name: 'Showcased', icon: <FaHeart size={20} color='#616161' /> },
        { name: 'Most Liked', icon: <GiRoundStar size={20} color='#616161' /> },
        { name: 'Followings', icon: <AiFillLike size={20} color='#616161' /> },
        { name: 'Newest', icon: <IoIosTime size={20} color='#616161' /> }
    ];
    const afterModelData = [
        { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', }, { name: 'AFTER SEX', },]


    const handleFilter = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchImageData = async () => {
            setLoading(true);
            const logger = localStorage.getItem('login');
            try {
                const response = await axios.get('https://api2.eromantic.ai/api/v1/get_allPublic_image/');
                console.log(response.data, 'Fetched Image Data');
                setImageData(response.data); // Set fetched image data to state
                setUserDetails(logger);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };
        fetchImageData();
    }, []);

     const handleImageView = (imgId) => {
            navigate(`/dashboard/ImageView/${imgId}`);
        };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    // Define the number of columns for the grid (5 columns per row)
    const columnCount = 5;
    const columnWidth = Math.floor(window.innerWidth / columnCount); // Adjust column width dynamically
    const rowCount = Math.ceil(imageData.length / columnCount);

    return (
        <div className='mt-20 lg:px-10 2xl:px-20 px-2 pb-20 bg-black pt-10 text-white font-josefin-sans'>
            <div className='flex justify-center xl:justify-between flex-wrap items-center'>
                <div className='m-2 px-3 py-2 w-[450px] text-black flex items-center gap-5 bg-white rounded-full'>
                    <IoSearchOutline color='black' size={23} />
                    <input type="text" placeholder='Search anything' className='bg-white rounded-full w-full focus:outline-none text-xl' />
                </div>
                <div className='flex justify-center lg:justify-start items-center flex-wrap'>
                    {Showcasedata.map((item, index) => (
                        <div key={index} className="w-60 md:w-48 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] hover:cursor-pointer hover:bg-[#FF00133D] border-zinc-600 text-white text-lg rounded-full hover:border-red-700 hover:text-red-500">
                            {item.icon}
                            <p className='text-center'>{item.name}</p>
                        </div>
                    ))}
                </div>
                 <div className='flex gap-5 items-center m-2 hover:cursor-pointer' onClick={handleFilter} >
                    <p className={`font-semibold text-lg ${open ? 'text-red-500' : 'text-white'  }`}>Filters</p>
                    <img src={open ? filterOn : filter} alt="filter" />
                </div>
            </div>
            {
                    open &&
                    <div>
                        <div className="flex flex-wrap items-center justify-center space-x-4 px-4 py-2 border-2 border-zinc-600 bg-[#6161614A] text-white rounded-lg mt-5">
                            <div className='border-r-2 border-zinc-600 flex justify-center'>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">NORMAL</button>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">EXPENDED</button>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">UPSCALED</button>
                            </div>
                            <div className='border-r-2 border-zinc-600 flex justify-center'>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">IMAGE</button>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">VIDEO</button>
                            </div>
                            <div className='border-r-2 border-zinc-600 flex justify-center'>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">REALISTIC</button>
                                <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">ANIME</button>
                            </div>
                            <button className="px-4 py-2 text-white rounded-lg hover:text-zinc-600 focus:outline-none">NSFW</button>
                        </div>

                        <div className='border-2 px-11 py-8 border-zinc-600 bg-[#6161614A] rounded-xl w-full h-[465px] overflow-y-auto scrollbar-thumb-rose-500 mt-5'>
                            <div className='flex flex-wrap justify-center gap-7 '>
                                {
                                    afterModelData.map((item1, index) => {
                                        return (
                                            <div key={index} className='bg-[#ECECEC] w-[268px] h-[172px] rounded-2xl mt-3'>
                                                <div className='aftersexBg opacity-60 w-[268px] h-[172px] rounded-2xl flex justify-center items-center'>
                                                    <p className='text-xl'>{item1.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }

            {/* Grid layout for images with 5 columns */}

        <div className='flex justify-center  items-center flex-wrap'>
                            {imageData.map((item, index) => (
                            <div className="relative hover:cursor-pointer" onClick={() => handleImageView(item.generation_id)}>
                            <img
                                alt={item.generation_prompt} // You can dynamically set this to describe the image
                                loading="lazy"
                                width="300"
                                height="300"
                                decoding="async"
                                data-nimg="1"
                                className="rounded-3xl m-4 "
                                srcSet={`https://api2.eromantic.ai/media/output/${item.image_file_name}?w=384&q=90 1x, https://api2.eromantic.ai/media/output/${item.image_file_name}?w=640&q=90 2x`}
                                src={`https://api2.eromantic.ai/media/output/${item.image_file_name}?w=640&q=90`}

                                style={{ color: 'transparent' }}
                            />

                <ImgDetailOnhoverGenration username={item.user_name} prompt={item.generation_prompt} model={item.Aimodel} extensions={item.extensions} />
            </div>

                            ))}
                        </div>
        </div>
    );
};

export default Discover;
