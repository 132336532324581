import React from 'react'
import { Rectangle13, Rectangle14, Rectangle15, Rectangle19, Rectangle20, Rectangle60 } from '../../../assets'
// import { Rectangle13, Rectangle14, Rectangle15, Rectangle19, Rectangle20, Rectangle60 } from '../../assets'

const OurcommunitySection = () => {
    return (
        <div className="bg-red-800 text-[#FFFFFF]">
            <div>
                <p className="text-[18px] md:text-[24px] lg:text-[36px] text-center py-5">Few images created by our community members.</p>
                <div className='flex flex-wrap gap-2 md:gap-4 justify-center items-center mx-1 md:mx-48 pb-10'>
                    <div className=''>
                        <div className='flex flex-col gap-2 md:gap-4 '>
                            <div className='flex gap-2 md:gap-4'>
                                <div className='flex flex-col gap-2 md:gap-4'>
                                    <img src={Rectangle13} alt="Rectangle13" className="" />
                                    <img src={Rectangle19} alt="Rectangle19" className="" />
                                </div>
                                <div className='flex flex-col gap-2 md:gap-4'>
                                    <img src={Rectangle14} alt="Rectangle14" className="" />
                                    <img src={Rectangle13} alt="Rectangle13" className="" />
                                </div>
                            </div>
                            <div>
                                <img src={Rectangle20} alt="Rectangle20" className="w-[655px]" />
                            </div>
                        </div>
                    </div>


                    <div className='flex flex-col gap-2 md:gap-3 w-auto justify-center items-center'>
                        <img src={Rectangle15} alt="Rectangle15" className="" />
                        <div className='flex flex-row gap-2 md:gap-4 h-auto'>
                            <img src={Rectangle13} alt="Rectangle13" className="w-44 md:w-full h-[248px] md:h-60 lg:h-[410px] xl:h-full" />
                            <img src={Rectangle13} alt="Rectangle13" className="w-44 md:w-full h-[248px] md:h-60 lg:h-[410px] xl:h-full" />
                        </div>
                    </div>
                </div>

                <div className='md:flex md:mx-20 xl:mx-36 2xl:mx-72 gap-2 xl:gap-1 2xl:gap-16 items-center py-10 lg:py-14 2xl:py-20 text-center md:text-left'>
                    <div className="">
                        <p className="text-[18px] lg:text-[24px] xl:text-[36px]">AI VIDEO GENERATOR</p>
                        <p className="text-[16px] lg:text-[18px] xl:text-[29px] leading-none font-light xl:font-normal md:pt-2 md:w-[320px] lg:w-[420px] xl:w-[641px] mx-10 md:mx-0 my-5">Our cutting-edge tool redefines the creative process, allowing you to effortlessly craft personalized videos that mirror your imagination.</p>
                    </div>
                    <div className="">
                        <img src={Rectangle60} alt="Rectangle60" className='w-[390px] h-[223px] md:w-[550px] 2xl:w-[650px] md:h-[283px] 2xl:h-[383px] px-2 md:px-0 md:mx-5' />
                    </div>
                </div>






            </div>
        </div>
    )
}

export default OurcommunitySection