import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { IoThumbsDownOutline , IoThumbsUpOutline, IoVolumeHighSharp } from "react-icons/io5";

const Aidreamchat = () => {

    const navigate = useNavigate();
    const NewGenerate = () => {
        // navigate("/dashboard");
    }


    return (
        <>
            <div className='px-5 xl:px-20 pb-20 bg-black pt-10 text-white font-josefin-sans min-h-[53rem]'>
                <div className="flex border border-[#9F9D9D] rounded-lg min-h-[50rem]">
                    <div className="flex-1 w-1/4 border border-[#9F9D9D] rounded-tl-lg rounded-bl-lg custm-bg-chat">
                        <div className="flex items-center justify-center text-2xl min-h-[5rem]">Chat with GirlFriend</div>
                        <div className=''>
                            <div className='flex items-center justify-center border border-[#000] rounded-xl bg-[#000] min-h-[5rem] m-[30px] p-2'>
                                <div><img src="/static/media/img1.9322f9a528d87450122a.png" alt="userProfile" class="w-12 h-12 rounded-full" /></div>
                                <div className='px-2'>
                                    <div className='flex items-center justify-between'><p className="text-2xl">Catalina
                                        <span class="inline-block w-3 h-3 bg-green-600 rounded-full mx-3 mb-[2px] border-2 border-green-600 shadow-md shadow-green-800"></span>
                                    </p><p className="text-2xl">23:10</p></div>
                                    <div><p className="text-md">Hey! Just wrapped up a busy day at ....</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-3 w-3/4">
                        <div className="flex items-center text-2xl px-5 border border-[#9F9D9D] rounded-tr-lg min-h-[5rem] custm-bg-head">
                            <img src="/static/media/img1.9322f9a528d87450122a.png" alt="userProfile" class="w-14 h-14 rounded-full mx-4" />Catalina</div>
                        <div className='min-h-[40rem] py-[40px] px-5 chatbackgr' >
                            <div class="flex justify-start m-6">
                                <div class="flex bg-[#FD818A] max-w-[60%] border border-[#88ceff] rounded-tr-2xl rounded-bl-2xl rounded-br-2xl p-2.5 pl-4">
                                    hi how are you
                                   <div> <div className='text-red-500 border bg-[#ffffff] p-[5px] mx-2 rounded-full shadow-lg'><IoVolumeHighSharp /></div></div>
                                </div>
                                <div className='flex h-[30px]'><div className='border bg-[#262626] p-[6px] ml-3 rounded-full'><IoThumbsUpOutline/></div>
                                <div className='border bg-[#262626] p-[6px] mx-2 rounded-full'><IoThumbsDownOutline /></div></div>
                                
                            </div>
                            <div class="flex justify-end m-6">
                                <div class="bg-[#494848] max-w-[60%] border border-[#88ceff] rounded-tl-2xl rounded-bl-2xl rounded-br-2xl p-2.5 pl-4">
                                    hi
                                </div>
                            </div>

                        </div>
                        <div className='bg-gray-700 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-90 h-38 mx-4'>
                            <input type="text" className='focus:outline-none text-white bg-gray-700 w-full' placeholder='Type Your Message...'
                            // onChange={handleFaceoptionalInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Aidreamchat;
