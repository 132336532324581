import { Rectangle50, Rectangle57, Rectangle63, Rectangle64, Rectangle65, Rectangle66 } from ".";

export const buttonsData = [
    { placeholder: 'Shirtlift', },
    { placeholder: 'Handjob', },
    { placeholder: 'Pussy', },
    { placeholder: 'Missionary', },
    { placeholder: 'Blowjob + Face-swap', },
    { placeholder: 'Handjob + Interracial', },
    { placeholder: 'Huge (Dildo Insertion)', }
];
export const imageData = [
    { src: Rectangle65, alt: 'Rectangle62' },
    { src: Rectangle63, alt: 'Rectangle63' },
    { src: Rectangle64, alt: 'Rectangle64' },
    { src: Rectangle65, alt: 'Rectangle65' },
    { src: Rectangle66, alt: 'Rectangle66' }
];

export const accordionData = [
    {
        title: "What is EROMANTICAI?",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with "
    },
    {
        title: "What is EROMANTICAI?",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with "
    },
    {
        title: "What is EROMANTICAI?",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with "
    },
    {
        title: "What is EROMANTICAI?",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with "
    }
];

export const blogPosts = [
    {
        id: 1,
        title: "Is SeducedAI Safe? A Detailed Guide",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursus pellentesque. Morbi odio odio, hendrerit ...",
        image: Rectangle50,
    },
    {
        id: 2,
        title: "Is SeducedAI Safe? A Detailed Guide",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursus pellentesque. Morbi odio odio, hendrerit ...",
        image: Rectangle50
    },
    {
        id: 3,
        title: "Is SeducedAI Safe? A Detailed Guide",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursus pellentesque. Morbi odio odio, hendrerit ...",
        image: Rectangle50
    },
    {
        id: 4,
        title: "Is SeducedAI Safe? A Detailed Guide",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursus pellentesque. Morbi odio odio, hendrerit ...",
        image: Rectangle50
    },
    {
        id: 5,
        title: "Is SeducedAI Safe? A Detailed Guide",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursus pellentesque. Morbi odio odio, hendrerit ...",
        image: Rectangle50
    },
    {
        id: 6,
        title: "Is SeducedAI Safe? A Detailed Guide",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursus pellentesque. Morbi odio odio, hendrerit ...",
        image: Rectangle50
    }
];

export const Categories = [
    {
        plush: '+',
        title: 'Categories (6)',
    },
    {
        plush: '+',
        title: 'Categories (6)',
    },
    {
        plush: '+',
        title: 'Categories (6)',
    },
    {
        plush: '+',
        title: 'Categories (6)',
    },
    {
        plush: '+',
        title: 'Categories (6)',
    },
    {
        plush: '+',
        title: 'Categories (6)',
    },
]
export const postData = [
    {
        id: 1,
        image: Rectangle57,
        title: "Is SeducedAI Safe?",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. ..."
    },
    {
        id: 2,
        image: Rectangle57,
        title: "Is SeducedAI Safe?",
        description: "Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. ..."
    }
];
