//import React from 'react'
//import BlogListCard from '../../../components/BlogListCard'
//import { useNavigate } from 'react-router-dom'
//import { blogListCard } from '../../../../assets'
//import PagenameSearchBox from '../../../components/PagenameSearchBox'
//
//const BlogList = () => {
//    const navigate = useNavigate()
//
//    const handleBlog = () => {
//        navigate('/admin/createBlog')
//    }
//
//    const cradData = [{ img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' },]
//
//    return (
//        <>
//            <div className='bg-white px-7 pb-20 font-josefin-sans'>
//                <PagenameSearchBox title='Blog List' placename='Search Blog' btnname='CREATE BLOG' btnfunction={handleBlog} />
//                <div className='flex flex-wrap justify-center gap-8 pt-8'>
//                    {
//                        cradData.map((item, index) => {
//                            return <BlogListCard key={index} img={item.img} title={item.title} subtext={item.subtext} />
//                        })
//                    }
//                </div>
//            </div>
//        </>
//    )
//}
//
//export default BlogList


import React, { useState, useEffect } from 'react';
import BlogListCard from '../../../components/BlogListCard';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PagenameSearchBox from '../../../components/PagenameSearchBox';

const BASE_URL = "https://api2.eromantic.ai/api/";

const BlogList = () => {
    const navigate = useNavigate();

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
            const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`https://api2.eromantic.ai/api/show_blog_post/`, {
		    headers: {
			'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
		    }
		});
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleBlog = () => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            // Redirect to login or show an error
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/createBlog');
    };

    return (
        <div className='bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox title='Blog List' placename='Search Blog' btnname='CREATE BLOG' btnfunction={handleBlog} />
            <div className='flex flex-wrap justify-start gap-8 pt-8'>
                {blogs.map((blog) => (
                    <BlogListCard
                        key={blog.blog_id}
                        blogId={blog.blog_id}
                        img={blog.image}  // Assuming your API response has the image URL
                        title={blog.title}
                        subtext={''}
                        blog_publish={blog.blog_publish}
                    />
                ))}
            </div>
        </div>
    );
};

export default BlogList;
